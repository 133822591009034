import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  background: ${theme.colors.backgroundPrimary};
  border: 1px solid ${theme.colors.borderBrand};
  border-radius: ${theme.spacing.xs};
  overflow: clip;
  padding: ${theme.spacing.lg};
  position: relative;
`;

export const Background = styled.img`
  left: 50%;
  position: absolute;
  top: 83%;
  translate: -50% -50%;
  width: 101%;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};
  margin: 0 auto;
  position: relative;
  max-width: 720px;
  width: 100%;
`;

export const Intro = styled.div`
  ${theme.typography.headingLG};
  text-align: center;
`;

export const Search = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
`;

export const SearchTiny = styled(Search)`
  width: 410px;
`;

export const FormWrapper = styled.div`
  border: 1px solid ${theme.colors.borderBrand};
  border-radius: ${theme.spacing.xs};
  box-shadow: ${theme.mixins.shadowDark};
  overflow: clip;

  fieldset {
    border: 0;
  }
`;

export const Category = styled.h4`
  ${theme.typography.label};
  padding: ${theme.spacing.sm} ${theme.spacing.md} ${theme.spacing.xs};
`;

export const Result = styled.li`
  &&& {
    align-items: center;
    display: flex;
    gap: ${theme.spacing.md};
    padding: ${theme.spacing.sm} ${theme.spacing.md};
  }
`;

export const ResultLogo = styled.div`
  align-items: center;
  border: 1px solid ${theme.colors.borderDark};
  border-radius: ${theme.spacing.xs};
  display: flex;
  justify-content: center;
  height: 32px;
  overflow: clip;
  width: 32px;

  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
`;

export const ResultIcon = styled.div`
  background: ${theme.colors.backgroundBrandLight};
  border-radius: ${theme.spacing.xs};
  color: ${theme.colors.textBrand};
  height: 32px;
  padding: ${theme.spacing.xxs};
  width: 32px;
`;

export const ResultTitle = styled.div`
  ${theme.typography.bodyLG}
`;

export const ResultType = styled.div`
  ${theme.typography.bodySM};
  color: ${theme.colors.textTertiary};
`;

export const SupportingInfo = styled.div`
  ${theme.typography.bodyLG};
  display: flex;
  justify-content: space-between;
`;

export const Amount = styled.span`
  ${theme.typography.headingXS};
`;
